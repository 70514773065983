<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">
        <div class="va-card">
          <div class="row">
            <div class="col-md-12">
              <div class="dash_card_body">
                <div class="header_section">
                  <h4 class="menu_header">Item Reviews</h4>
                </div>
              </div>
              <hr>
            </div>
            <div class="col-md-12">
              <div class="dash_card_body">
                <div class="topB">
                  <span style="padding-top: 1rem;" class="pull-left">Customers give your menu items a 5-star rating after delivery.  This metric is the average of all your item ratings.</span>
                  <div class="pull-right avarage-R">
                    <span class="pcent">92.3%</span>
                    <ul class="list">
                      <li :key="star" v-for="star in maxStars" class="star five_star_rate">
                        <span class="far fa-star"></span>
                      </li>
                    </ul>
                    <span class="av">Average Rating</span>

                  </div>
                </div>
                <div class="review_questions mt-2 mb-4">
                  
                  <div class="row">
                    <div class="col-md-6 col-sm-12">
                      <h3>Positive Reviews</h3>
                      <span class="badge badge_review mr-2 mt-2" v-for="preview in positiveReviews" :key="preview.name">
                        {{preview.name}} &nbsp; <small>{{preview.reviews > 1 ?preview.reviews+ " Reviews" : preview.reviews + " Review"}} </small>
                      </span>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <h3>Negative Reviews (Areas for improvement)</h3>
                      <span class="badge badge_review mr-2 mt-2" v-for="nreview in negativeReviews" :key="nreview.name">
                        {{nreview.name}} &nbsp; <small>{{nreview.reviews > 1 ?nreview.reviews+ " Reviews" : nreview.reviews + " Review"}} </small>
                      </span>
                    </div>
                  </div>

                </div>
                <div class="item_reviews"> 
                  <div class="review_questions mt-2 mb-5">
                    <div class="row bd-b">
                      <div class="col-md-5 col-sm-12 rHeader"> 
                        
                        <h3>Item Rank</h3>
                        <div class="filtR"><span>{{orderingMsg}}</span> <span class="ml-3" > <i class='fas fa-arrow-up' @click="orderRanksBy('desc')"></i> <i @click="orderRanksBy('asc')" class='fas fa-arrow-down'></i></span></div>
                        
                      </div>
                      <div class="col-md-7 col-sm-12 rHeader">
                        <h3>Customer  Reviews</h3>

                        <div class="filtR"><span>Last 7 days:</span> <span class="ml-2" style="font-weight:400"> {{reviewItems.length > 1 ? reviewItems.length+ " Reviews" : reviewItems.length + " Review"}}</span></div>
                        
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-5 col-sm-12 bd-right">
                        
                        <div class="review_event cust_review" v-for="itemrank in itemRanks" :key="itemrank.item_name">
                          <div class="knob"></div>
                          <div class="review_date">
                            <h2>{{itemrank.item_name}}</h2>
                          </div>
                          <div class="review_description mt-3">
                            
                            <span>{{itemrank.ratio}} %</span>  &nbsp; <span> <i class='fa fa-thumbs-up'></i> {{itemrank.likes}}</span>  &nbsp; <span style="color:#858585"> <i class='fa fa-thumbs-down'></i> {{itemrank.dislikes}}</span>
                          </div>
                        </div>
                   



                      </div>
                      <div class="col-md-7 col-sm-12">
                        <div class="review_event cust_review" v-for="review in reviewItems" :key="review.id">
                          
                          <div class="review_date">
                            <h2>{{formatDate(review.created_at)}}</h2>
                          </div>
                          <div class="review_description">
                            
                            <div  class="item_review mt-3" v-for="item in review.review_items" :key="item.id">
                              <span><b><span v-if="item.status"><i class='fa'>&#xf087;</i></span> 
                                <span v-else><i class='fa'>&#xf088;</i></span> {{item.item_name}}</b></span>  <br>
                              <div class="mt-2">
                                <span v-for="(reviewquiz, qindex) in item.review_questions" :key="reviewquiz.name">
                                  {{reviewquiz.name}} <span v-if="qindex+ 1 < item.review_questions.length"> . </span>
                                </span>
                              </div>        
                            </div>
                          </div>

                          

                        </div>
                        <div id="pagination" v-if="totalPages > 1">
                          <ul class="showItems">
                            <li>Show Items:
                              <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                <option :value="10">10</option>
                                <option :value="20">20</option>
                                <option :value="30">30</option>
                                <option :value="40">40</option>
                                <option :value="50">50</option>
                              </select>
                            </li>

                          </ul>
                          <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />

                        </div>
                        
                      </div>
                    </div>
                    <!-- pagination -->
                    <div id="pagination" v-if="totalRankPages > 1">
                      <ul class="showItems">
                        <li>Show Items:
                          <select @change="onRankChangeRecordsPerPage" v-model="ranksPerPage">
                            <option :value="10">10</option>
                            <option :value="20">20</option>
                            <option :value="30">30</option>
                            <option :value="40">40</option>
                            <option :value="50">50</option>
                          </select>
                        </li>

                      </ul>
                      <Pagination :total-pages="totalRankPages" :per-page="ranksPerPage" :current-page="rankspage" @pagechanged="onRankPageChange" />

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import Pagination from '@/pages/pagination/pagination.vue';
// import { useToast } from "vue-toastification";
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
// const toast = useToast();

export default {
    components: {
        Pagination,
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            stores: [],
            // configuresStores:[],
            loading: false,
            spinner: false,
            page: 1,
            reviews: [],
            reviewItems: [],
            totalPages: 0,
            totalRecords: 0,
            recordsPerPage: 5,
            enterpageno: '',
            search: '',
            negativeReviews: [],
            positiveReviews: [],
            itemRanks: [],
            ranksPerPage:20,
            rankspage:1,
            totalRankPages:0,
            debounce: null,
            orderBy: '',
            maxStars: 5,
            stars: 3,
            orderingMsg: 'Highest to lowest rated'
        }
    },
    validations() {
        return {
            name: { required },
            status: { required },
            type: { required },
            questiontype: { required }
        }
    },
    created: async function () {
        this.getItemReviews();
        this.getItemRanks();
    },
    methods: {
      orderRanksBy(order){
        this.orderBy = order;
        if(order === 'desc'){
          this.orderingMsg = 'highest to Lowest rated'
        }else{
          this.orderingMsg = 'Lowest to Highest rated'
        }
        this.getItemRanks();
      },
      searchReviewQuestions() {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.getItemReviews();
            }, 1500)
        },
        async getItemReviews(){
          this.loading =true;
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            let url = `${this.dashBaseUrl}/item-reviews/${this.merchantID}`;
            url = new URL(url);
            url.searchParams.set('page', this.page);
            url.searchParams.set('size', this.recordsPerPage);
            try{
              const response = await this.axios.get(url, config)
              if (response.status === 200) {
                  this.reviewItems = response.data.data.review_items.data;
                  this.negativeReviews = response.data.data.negative_review_questions
                  this.positiveReviews = response.data.data.positive_reviews_questions
                  this.totalPages = Math.ceil(response.data.data.review_items.total / this.recordsPerPage)
                  this.totalRecords = response.data.data.review_items.total
                  this.loading = false
              }
            }catch(error){
              this.loading = false;
            }
          // item-reviews
        },
        async getItemRanks(){
          const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            let url = `${this.dashBaseUrl}/item-ranks/${this.merchantID}`;
            url = new URL(url);
            url.searchParams.set('page', this.rankspage);
            url.searchParams.set('size', this.ranksPerPage);
            if(this.orderBy !==''){
              url.searchParams.set('order', this.orderBy);
              
            }
            try{
              const response = await this.axios.get(url, config)
              if (response.status === 200) {
                  this.itemRanks = response.data.data.data;
                  this.totalRankPages = Math.ceil(response.data.data.total / this.ranksPerPage)
                  this.totalRecords = response.data.data.total
                  // this.loading = false
              }
            }catch(error){
              this.loading = false;
            }
        },
        onPageChange(page) {
            this.page = page
            this.getItemReviews();
        },
        onChangeRecordsPerPage() {
            this.getItemReviews();
        },

        onRankPageChange(page) {
            this.rankspage = page
            this.getItemRanks();
        },
        onRankChangeRecordsPerPage() {
            this.getItemRanks();
        },

    },
    mixins: [ReusableDataMixins, ReusableFunctionMixins]
}
</script>
<style>

</style>
